.checkout-cart_body {
    &.--checkout header {
        .header__logo {
            max-width: 15rem;
            display: flex;
        }
        .menu__toggle, .header__nav, .right__menu > div {
            display: none;
        }
    }
    &.--checkout {
        footer {
            > .grid-container {
                display: none;
            }
            .absolute__footer {
                padding: 0;
                > .grid-container {
                    border: none;
                }
            }
        }
        .grid-container {
            max-width: 100rem;
        }
        .modal__overlay .modal__wrapper.login__modal {
            min-width: 50rem;
            @include breakpoint(small only) {
                min-width: 50%;
            }
            .modal__field {
                margin: 0 0 1.5rem;
            }
            ._actions {
                display: flex;
                gap: 1rem;
                align-items: center;
            }
        }
    }
    .checkout-cart {
        ._header ._holder {
            ._back {
                @include breakpoint(small only) {
                    position: absolute;
                    height: 3.2rem;
                }
                button, a {
                    cursor: pointer;
                    display: block;
                    width: 14px;
                    height: 27px;
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='27px' viewBox='0 0 14 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='%230A233D' stroke-width='3.1' points='12 26 2 13.3307805 12 1'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                    margin-right: 1.5rem;
                    background-size: 1rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    @include breakpoint(medium) {
                        @include transition;
                        &:hover {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='27px' viewBox='0 0 14 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, PrimaryBlueHover)}' stroke-width='3.1' points='12 26 2 13.3307805 12 1'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                    @include breakpoint(small only) {
                        background-image: url("data:image/svg+xml,%0A%3Csvg width='14px' height='27px' viewBox='0 0 14 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='%230A233D' stroke-width='3.1' points='12 26 2 13.3307805 12 1'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
                        background-position: center;
                        background-repeat: no-repeat;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .checkout-error-container {
            margin: 0 0 2rem;
        }
        .login__block {
            background: color(base, LightSnow);
            padding: 1.5rem 2rem;
            margin: 0 0 2rem;
            display: flex;
            border-radius: 0.5rem;
            font-size: 1.3rem;
            @include breakpoint(medium) {
                max-width: 90%;
            }
            > span {
                color: color(gray, Gray-400);
            }
            button:not(._button) {
                cursor: pointer;
                color: color(base, PrimaryPink);
                margin-left: auto;
                text-decoration: underline;
                @include breakpoint(medium) {
                    &:hover {
                        color: color(base, PrimaryPinkHover);
                    }
                }
            }
        }
        .form-field-container {
            @include breakpoint(medium) {
                width: 90%;
            }
        }
        .field__group {
            &.--new-account {
                margin-bottom: 2rem;
            }
            select {
                padding: 0 2.4rem 0 1.5rem;
            }
            @include breakpoint(medium) {
                &.names {
                    > div {
                        flex: 1;
                    }
                }
            }
            .checkout__field {
                flex: 1;
                input {
                    &.failed {
                        border-color: color(alert, Error);
                    }
                    &.valid {
                        border-color: color(alert, Success);
                        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ECheck%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='6.13132462 8.81921546 3.04945992 5.73735077 2 6.77942013 6.13132462 10.9107447 15 2.04206936 13.9579306 1'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Check' stroke='%23178038' stroke-width='1.5' fill='%23178038' fill-rule='nonzero' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: right 10px center;
                        background-size: 12px;
                    }
                }
                &.telephone {
                    margin: 0 0.5rem 0;
                }
            }
            margin: 1.5rem -.5rem 1rem;
            display: flex;
            > div {
                margin: 0 .5rem;
            }
            &.checkout__login-container {
                flex-wrap: wrap;
                .password {
                    flex-basis: 100%;
                    margin: 0 .5rem 1rem;
                }
                .actions {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    gap: 1rem;
                    button {
                        flex-basis: 50%;
                    }
                }
            }
        }
        .shipping__address, .billing__address {
            h2 {
                font-size: 1.8rem;
                line-height: normal;
                padding: 0 0 1rem;
                font-weight: 500;
                color: color(base, Black);
            }
            &:is(.shipping__address) {
                h2 {
                    margin: 2rem 0 0;
                }
            }
            .shipping-address-select {
                margin: 0 0 3rem;
                label {
                    margin: 0 0 1rem;
                }
                .address-overview {
                    display: flex;
                    .address-overview-items {
                        padding-right: 1rem;
                        padding-top: 1rem;
                    }
                }
            }
            .checkout__address-autocomplete_result {
                margin: 0 0 2rem;
                label {
                    font-size: 1.3rem;
                    cursor: pointer;
                    text-decoration: underline;
                }
                .resolved__address {
                    span {
                        display: block;
                        font-weight: normal;
                        font-size: 1.3rem;
                        &._error {
                            color: color(alert, Error);
                            margin: 0 0 1rem;
                        }
                    }
                    address {
                        background: color(base, LightSnow);
                        display: inline-flex;
                        padding: 1.5rem;
                        min-width: 100%;
                        font-size: 1.4rem;
                        border-radius: 0.5rem;
                        line-height: 2.2rem;
                        @include breakpoint(small only) {
                            width: 100%;
                        }
                    }
                }
            }
            .billing-same-as-shipping-control {
                display: flex;
                align-items: center;
                font-size: 1.3rem;
                label:not(.vue-js-switch) {
                    margin-left: 1rem;
                }
            }
            .shipping-address-select{
                font-size: 1.3rem;
                label {
                    font-weight: bold;
                }
            }
        }
        .shipping__address {
            .email__group {
                display: flex;
                flex-wrap: wrap;
                gap: .5rem;
                margin: .5rem 0 0;
                .checkout__field.email {
                    margin: 0;
                    flex: 1 1 100%;
                    &:has(.similarity-message) {
                        .field-notification {
                            display: none;
                        }
                    }

                }
                .checkout__login__link {
                    font-size: 1.4rem;
                    span {
                        color: color(base, PrimaryPink);
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }
            .shipping__address-select {
                label {
                    font-size: 1.4rem;
                }
            }
        }
        .billing__address {
            .shipping-address-select {
                margin: 3rem 0;
            }
        }
        .checkout-footer {
            margin: 3rem 0;
            ._place-order {
                display: grid;
                a {
                    color: color(gray, Gray-300);
                    font-size: 1.2rem;
                    margin: .5rem 0 0;
                    span {
                        font-size: 1.2rem;
                        text-decoration: underline;
                        font-weight: normal;
                    }
                }
            }
            button {
                min-width: 40%;
                @include breakpoint(small only) {
                    width: 100%;
                }
            }
        }
        .checkout-footer, .cart__side-bar-container .cart__side-bar ._actions {
            button {
                height: 4rem;
                line-height: 4rem;
                border-radius: 2rem;
                padding: 0 2rem;
                font-size: 1.6rem;
            }
        }
        .payment__methods {
            .payment__method__options {
                padding: 1rem 2rem 1.5rem 5rem;
                @include breakpoint(medium) {
                    max-width: 60%;
                }
            }
        }
        .address__summary-container {
            display: flex;
            flex-wrap: wrap;
            background: color(base, LightSnow);
            padding: 2rem;
            border-radius: 0.5rem;
            @include breakpoint(small only) {
                padding: 1.5rem 2rem 2rem;
            }
            .address__summary {
                width: 50%;
                font-size: 1.3rem;
                h4 {
                    font-weight: 500;
                    color: color(base, Black);
                    font-size: 1.5rem;
                    margin: 0 0 0.5rem;
                }
                ul {
                    li {
                        line-height: 2.2rem;
                        color: color(gray, Gray-300);
                    }
                }
            }
            button {
                color: color(base, PrimaryPink);
                margin: 1rem 0 0;
                font-size: 1.3rem;
                text-decoration: underline;
                @include breakpoint(medium) {
                    &:hover {
                        color: color(base, PrimaryPinkHover);
                    }
                }
            }
        }
        .coupon-code__container {
            margin: 3rem 0 0;
            .toggle-coupon {
                display: flex;
                gap: 1rem;
                align-items: center;
                font-size: 1.3rem;
                label {
                    cursor: pointer;
                }
            }
            .coupon-code__form {
                margin: 1rem 0 0;
                @include breakpoint(medium) {
                    max-width: 60%;
                }
                .form-holder {
                    display: flex;
                    input {
                        border-radius: 2rem 0 0 2rem;
                    }
                    button {
                        border-radius: 0 2rem 2rem 0;
                    }
                }
            }
        }
        .progress-bar {
            display: flex;
            margin: 0 0 1rem;
            @include breakpoint(medium) {
                margin: 0 0 2rem;
            }
            li {
                flex: 1;
                position: relative;
                &:not(:last-child) {
                    padding-right: 1rem;
                }
                &:not(:first-child)::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 1.6rem 0 1.6rem 1rem;
                    border-color: transparent transparent transparent color(base, White);
                    @include breakpoint(medium) {
                        border-width: 2rem 0 2rem 1rem;
                    }
                }
                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 1.6rem 0 1.6rem 1rem;
                    border-color: transparent transparent transparent color(gray, Gray-100);
                    @include breakpoint(medium) {
                        border-width: 2rem 0 2rem 1rem;
                    }
                }
                span {
                    background: color(gray, Gray-100);
                    display: block;
                    line-height: 3.2rem;
                    font-size: 1.3rem;
                    text-align: center;
                    padding-left: .5rem;
                    @include breakpoint(medium) {
                        line-height: 4rem;
                        font-size: 1.5rem;
                        border-bottom: .2rem solid color(gray, Gray-200);
                        cursor: pointer;
                    }
                }
                &.--active, &.--complete {
                    &::after {
                        border-color: transparent transparent transparent color(base, PrimaryPink);
                    }
                    span {
                        background: color(base, PrimaryPink);
                        color: color(base, White);
                        @include breakpoint(medium) {
                            border-color: color(base, PrimaryPinkHover);
                        }
                    }
                }
            }
        }
    }
    .checkout-footer, ._actions {
        button._cart-loading {
            background-image: url("data:image/svg+xml,%0A%3C!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL --%3E%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='8.042%25' y1='0%25' x2='65.682%25' y2='23.865%25' id='a'%3E%3Cstop stop-color='#{color(base, SecondaryBlue)}' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='#{color(base, SecondaryBlue)}' stop-opacity='.631' offset='63.146%25'/%3E%3Cstop stop-color='#{color(base, SecondaryBlue)}' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18' id='Oval-2' stroke='url(%23a)' stroke-width='2'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/path%3E%3Ccircle fill='#{color(base, SecondaryBlue)}' cx='36' cy='18' r='1'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left 1.5rem center;
            background-size: 2rem;
        }
    }
    .checkout__newsletter {
        margin: 3rem 0 0;
        padding: 3rem 0 0;
        border-top: 1px solid color(gray, Gray-100);
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        label:last-child {
            margin-left: 1rem;
        }
    }
    .vfl-has-label {
        .vfl-label-on-input {
            color: color(base, DefaultFontColor);
            top: -2rem;
        }
    }
    .checkout-step-container {
        .billing__address {
            margin: 3rem 0 0;
        }
        .shipping__methods, .payment__methods {
            position: relative;
            ._loading {
                position: static;
                background: transparent;
                ._spinner {
                    margin: 2rem 0;
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }
            .block__holder {
                .shipping__methods-list, .payment__methods-list {
                    li {
                        .shipping__method, .payment__method {
                            input[type='radio'] {
                                width: 1.5rem;
                                height: 1.5rem;
                                margin: 0.1rem 1rem 0 0;
                                max-width: 3rem;
                                flex: 1;
                            }
                            label {
                                background: color(base, TertiaryBlue);
                                border-radius: .5rem;
                                cursor: pointer;
                                font-size: 1.4rem;
                                display: flex;
                                align-items: center;
                                width: 100%;
                                margin: 0 0 1rem;
                                padding: 1.5rem 1rem;
                                border: .2rem solid transparent;
                                &.--active {
                                    border-color: lighten(color(base, SecondaryBlue), 20%);
                                }
                                @include breakpoint(medium) {
                                    &:hover {
                                        border-color: lighten(color(base, SecondaryBlue), 20%);
                                    }
                                }
                                img {
                                    margin-right: 1rem;
                                }
                                > span {
                                    display: block;
                                    flex: 1;
                                    ._title {
                                        font-weight: 600;
                                        display: flex;
                                        align-items: center;
                                        gap: .5rem;
                                        ._free {
                                            color: color(alert, Success);
                                            font-weight: 500;
                                        }
                                    }
                                    ._sub {
                                        color: color(gray, Gray-300);
                                        margin: .5rem 0 0;
                                        display: block;
                                        font-size: 1.3rem;
                                        line-height: 1.7rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
